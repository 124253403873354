// Sidebar.js
import React, { useState, useEffect } from "react";
import axios from "axios";

const Sidebar = ({ onSectorChange }) => {
  const [sectors, setSectors] = useState([]);
  const [sectorName, setSectorName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentSector, setCurrentSector] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchSectors = async () => {
      const response = await axios.get("http://localhost:5000/api/sectors", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSectors(response.data.sectors);
    };

    fetchSectors();
  }, []);

  const handleInputChange = (e) => {
    setSectorName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    // Check if sectorName is empty
    if (!sectorName.trim()) {
      console.error("Sector name is required");
      return;
    }

    try {
      if (isEditing) {
        await axios.put(
          `http://localhost:5000/api/sectors/${currentSector._id}`,
          { name: sectorName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setSectors(
          sectors.map((sector) =>
            sector._id === currentSector._id
              ? { ...sector, name: sectorName }
              : sector
          )
        );
      } else {
        const response = await axios.post(
          "http://localhost:5000/api/sectors",
          { name: sectorName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setSectors([...sectors, response.data.sector]);
      }
      resetForm();
    } catch (error) {
      console.error("Failed to save sector", error);
    }
  };

  const handleEdit = (sector) => {
    setSectorName(sector.name);
    setIsEditing(true);
    setCurrentSector(sector);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`http://localhost:5000/api/sectors/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSectors(sectors.filter((sector) => sector._id !== id));
    } catch (error) {
      console.error("Failed to delete product", error);
    }
  };

  const resetForm = () => {
    setSectorName("");
    setIsEditing(false);
    setCurrentSector(null);
  };

  return (
    <div
      style={{ borderRight: "1px solid #ccc", padding: "20px", width: "250px" }}
    >
      <h3>Sectors</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={sectorName}
          onChange={handleInputChange}
          placeholder="Sector Name"
          required
          style={{ padding: "10px", width: "100%", marginBottom: "10px" }}
        />
        <button
          type="submit"
          style={{ padding: "10px", width: "100%", marginBottom: "10px" }}
        >
          {isEditing ? "Update Sector" : "Add Sector"}
        </button>
        {isEditing && (
          <button
            type="button"
            onClick={resetForm}
            style={{ padding: "10px", width: "100%" }}
          >
            Cancel
          </button>
        )}
      </form>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {sectors.map((sector) => (
          <li key={sector._id} style={{ margin: "10px 0" }}>
            {sector.name}
            <button
              onClick={() => handleEdit(sector)}
              style={{ marginLeft: "10px" }}
            >
              Edit
            </button>
            <button
              onClick={() => handleDelete(sector._id)}
              style={{ marginLeft: "10px" }}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
