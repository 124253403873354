// Import images
import {
  rolex,
  energy,
  princess,
  Bega,
  logo,
  puro,
  law,
  west,
  masters,
  hotel,
  mainLogo,
  mbeturinat,
  sophra,
  sinjalistika2,
  skosmetics,
  pajtoni,
  pajtonik1,
  birthday,
  gezuar,
  aurora,
  cosmetics,
  denarbiba,
  etos,
  lawK,
  makina,
  parid,
  spa,
  taxi,

  // menu
  delivery,
  frigobar,
  gusta,
  peshku,
  small,
  streetMenu,
  streetMenu2,
  sushi,
  westMenu,

  // stickers
  badge,
  badge2,
  core,
  qrcode,
  stickergote,
  stickershishe,
  stickervere,
  giftcards,
  etosgiftcard,
  // Oliss
  // kanavac
  murale,
  murale2,
  murale3,
  murale5,
  murale6,
  panorama,
  qytet,
  tirona,

  // broshura
  certificate,
  creamy,
  flayer,
  glam,
  kampiveror,
  pajtoniflayer,
  paridbroshur,
  roomsofhotel,
  school,

  // promocionale
  flamuj,
  peshqiret,
  promocion1,
  promocion2,
  qrcodeprom,
  tgjitha,

  // banners
  alexstar,
  banner1,
  klinikakeit,
  futureoftirana,
  palester,
  reunion,
} from "./assets/index.js";

// JSON string
const menuJson = {
  categories: [
    {
      category_name: "Logo",
      image: mainLogo,
      products: [
        {
          name: "Birra King Pils 2001",
          description: "",
          price: "",
          image: logo,
        },
        {
          name: "Puro Gusto",
          description: "Limited",
          price: "",
          image: puro,
        },
        {
          name: "Daci & Associates",
          description: "Attorneys at Law",
          price: "",
          image: law,
        },
        {
          name: "Bar West",
          description: "If Doesnt Go Right, Go West",
          price: "",
          image: west,
        },
        {
          name: "Value Masters",
          description: "",
          price: "",
          image: masters,
        },
        {
          name: "Koko Hotel",
          description: "A Summer You’ll Never Forget!",
          price: "",
          image: hotel,
        },
      ],
    },
    {
      category_name: "Tabela dhe Germa 2D+3D",
      image: sophra,
      products: [
        {
          name: "Bar Rolex",
          description: "",
          price: "",
          image: rolex,
        },
        {
          name: "Energy Lounge",
          description: "",
          price: "",
          image: energy,
        },
        {
          name: "Beaty Princess",
          description: "",
          price: "",
          image: princess,
        },
        {
          name: "Tabela PLEX",
          description: "Bega Tax & Accounting",
          price: "",
          image: Bega,
        },
      ],
    },
    {
      category_name: "Adezive, Forex",
      image: birthday,
      products: [
        {
          name: "Comming Soon",
          description: "",
          price: "",
          image: pajtoni,
        },
        {
          name: "Pajtoni",
          description: "",
          price: "",
          image: pajtonik1,
        },
        {
          name: "Urime Per Ditelindje",
          description: "",
          price: "",
          image: gezuar,
        },
        {
          name: "Skosmetics",
          description: "Get Your MakeUp Done Here",
          price: "",
          image: skosmetics,
        },
        {
          name: "Mos Hidhni Mbeturinat",
          description: "",
          price: "",
          image: mbeturinat,
        },
        {
          name: "No Camping",
          description: "",
          price: "",
          image: sinjalistika2,
        },
      ],
    },

    // etos,
    // lawK,
    // makina,
    // parid,
    // spa,
    // taxi,
    {
      category_name: "Kartvizita",
      image: aurora,
      products: [
        {
          name: "Cosmetics",
          description: "",
          price: "",
          image: cosmetics,
        },
        {
          name: "Cairman",
          description: "",
          price: "",
          image: denarbiba,
        },
        {
          name: "Etos Perfumery",
          description: "",
          price: "",
          image: etos,
        },
        {
          name: "Person Juridik",
          description: "",
          price: "",
          image: lawK,
        },
        {
          name: "Makina Me Qera",
          description: "",
          price: "",
          image: makina,
        },
        {
          name: "Accounting Business",
          description: "",
          price: "",
          image: parid,
        },
        {
          name: "Hotel & Spa",
          description: "",
          price: "",
          image: spa,
        },
        {
          name: "Taxi",
          description: "",
          price: "",
          image: taxi,
        },
      ],
    },
    {
      category_name: "Menu",
      image: delivery,
      products: [
        {
          name: "",
          description: "",
          price: "",
          image: frigobar,
        },
        {
          name: "",
          description: "",
          price: "",
          image: gusta,
        },
        {
          name: "",
          description: "",
          price: "",
          image: peshku,
        },
        {
          name: "",
          description: "",
          price: "",
          image: small,
        },
        {
          name: "",
          description: "",
          price: "",
          image: streetMenu,
        },
        {
          name: "",
          description: "",
          price: "",
          image: streetMenu2,
        },
        {
          name: "",
          description: "",
          price: "",
          image: sushi,
        },
        {
          name: "",
          description: "",
          price: "",
          image: westMenu,
        },
      ],
    },
    {
      category_name: "Stickers",
      image: badge,
      products: [
        {
          name: "",
          description: "",
          price: "",
          image: badge2,
        },
        {
          name: "",
          description: "",
          price: "",
          image: core,
        },
        {
          name: "",
          description: "",
          price: "",
          image: qrcode,
        },
        {
          name: "",
          description: "",
          price: "",
          image: stickergote,
        },
        {
          name: "",
          description: "",
          price: "",
          image: stickershishe,
        },
        {
          name: "",
          description: "",
          price: "",
          image: stickervere,
        },

        {
          name: "",
          description: "",
          price: "",
          image: giftcards,
        },
        {
          name: "",
          description: "",
          price: "",
          image: etosgiftcard,
        },
      ],
    },
    {
      category_name: "Kanavac",
      image: murale,
      products: [
        {
          name: "",
          description: "",
          price: "",
          image: murale2,
        },
        {
          name: "",
          description: "",
          price: "",
          image: murale3,
        },
        {
          name: "",
          description: "",
          price: "",
          image: murale5,
        },
        {
          name: "",
          description: "",
          price: "",
          image: murale6,
        },

        {
          name: "",
          description: "",
          price: "",
          image: panorama,
        },
        {
          name: "",
          description: "",
          price: "",
          image: qytet,
        },
        {
          name: "",
          description: "",
          price: "",
          image: tirona,
        },
      ],
    },
    {
      category_name: "Flyer, Broshura, Ftesa, Zarfa",
      image: certificate,
      products: [
        {
          name: "",
          description: "",
          price: "",
          image: creamy,
        },
        {
          name: "",
          description: "",
          price: "",
          image: flayer,
        },
        {
          name: "",
          description: "",
          price: "",
          image: glam,
        },
        {
          name: "",
          description: "",
          price: "",
          image: kampiveror,
        },
        {
          name: "",
          description: "",
          price: "",
          image: pajtoniflayer,
        },
        {
          name: "",
          description: "",
          price: "",
          image: paridbroshur,
        },
        {
          name: "",
          description: "",
          price: "",
          image: roomsofhotel,
        },
        {
          name: "",
          description: "",
          price: "",
          image: school,
        },
      ],
    },
    {
      category_name: "Promocionale",
      image: tgjitha,
      products: [
        {
          name: "",
          description: "",
          price: "",
          image: flamuj,
        },
        {
          name: "",
          description: "",
          price: "",
          image: peshqiret,
        },
        {
          name: "",
          description: "",
          price: "",
          image: promocion1,
        },
        {
          name: "",
          description: "",
          price: "",
          image: promocion2,
        },
        {
          name: "",
          description: "",
          price: "",
          image: qrcodeprom,
        },
      ],
    },
    {
      category_name: "Banners, Roll -up",
      image: banner1,
      products: [
        {
          name: "",
          description: "",
          price: "",
          image: alexstar,
        },
        {
          name: "",
          description: "",
          price: "",
          image: klinikakeit,
        },
        {
          name: "",
          description: "",
          price: "",
          image: futureoftirana,
        },
        {
          name: "",
          description: "",
          price: "",
          image: palester,
        },
        {
          name: "",
          description: "",
          price: "",
          image: reunion,
        },
      ],
    },
  ],
};

// Now you can use the `menu` object in your application
export default menuJson;
