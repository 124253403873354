import React, { useState } from "react";
import menuJson from "./data";
import imageBanner from "./assets/images/banner.png";
import profileImage from "./assets/images/profile.png";
import {
  FaInstagram,
  FaPhone,
  FaClock,
  FaMapMarkerAlt,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa"; // Import new icons

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  return (
    <div
      style={{
        backgroundColor: "#F5F7F8",
        height: "100%",
        marginBottom: "10px",
      }}
    >
      <header style={{ position: "relative", textAlign: "center" }}>
        <img
          src={imageBanner}
          alt="Restaurant Banner"
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <img
            src={profileImage}
            alt="Restaurant Logo"
            style={{
              width: "200px",
              height: "200px",
              border: "5px solid #ddd",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        <div style={{ marginTop: "150px", zIndex: 1 }}>
          <h1 style={{ margin: "10px 0" }}>LOLA Studio</h1>
          <h5>"We may not be the best, but there's no one better than us."</h5>
          <div style={{ backgroundColor: "#F5F7F8", width: "100%" }}>
            <div style={styles.cardsContainer}>
              <Card
                icon={<FaInstagram />}
                link="https://www.instagram.com/lolaprintstudio/?hl=en"
                title="Instagram"
              />
              <Card
                icon={<FaPhone />}
                link="tel:+355 67 72 94 762"
                title="Call"
              />
              <div style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaClock />
                </div>
                <div style={styles.cardLink}>8:30 - 20:00</div>
              </div>
              <Card
                icon={<FaMapMarkerAlt />}
                link="https://maps.app.goo.gl/dWoJUnweJBxXMpJh7"
                title="Location"
              />
            </div>
          </div>
        </div>
      </header>

      <nav style={styles.nav}>
        {menuJson.categories.map((category) => (
          <div key={category.category_name} style={styles.category}>
            <div
              onClick={() => handleCategoryClick(category)}
              style={styles.button}
            >
              <div style={styles.titleDivCategory}>
                <div>
                  <img
                    src={category.image} // Ensure the path is correct
                    alt={category.category_name}
                    style={styles.categoryImage}
                  />
                </div>

                <div style={styles.categoryName}>{category.category_name}</div>
              </div>
              <div style={styles.categoryArrow}>
                {selectedCategory === category ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </div>
            </div>

            {selectedCategory === category && (
              <div style={styles.dropdown}>
                {category.products.map((product) => (
                  <div key={product.name} style={styles.productCard}>
                    <img
                      src={product.image}
                      alt={product.name}
                      style={styles.productImage}
                    />
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>
                    <p>{product.price}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
    </div>
  );
};

// Card component for social media and contact links
const Card = ({ icon, link, title }) => (
  <a href={link} style={{ ...styles.card, ...styles.cardLink }}>
    <div style={styles.cardHeader}>{icon}</div>
    <div>{title}</div>
  </a>
);

// Styles object for responsive design
const styles = {
  nav: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  categoryImage: {
    width: "70px",
    height: "70px",
    objectFit: "cover",
    borderRadius: "5px",
  },
  categoryName: {
    marginLeft: "20px",
    fontSize: "18px",
    fontWeight: 500,
  },
  categoryArrow: {
    alignSelf: "center",
  },
  category: {
    width: "90%",
    borderRadius: "20px",
    textAlign: "center",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: "20px",
    padding: "10px 20px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
  },
  dropdown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginTop: "10px",
  },
  productCard: {
    width: "90%",
    borderRadius: "10px",
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center",
  },
  productImage: {
    width: "100%",
    height: "250px",
    objectFit: "contain",
  },
  titleDivCategory: {
    display: "flex",
    height: "100%",
  },
  cardsContainer: {
    border: "1px solid #ddd",
    borderRadius: "10px",
    width: "95%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    margin: "20px auto",
    backgroundColor: "#fff",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
  },
  cardHeader: {
    fontSize: "30px",
    marginBottom: "5px",
  },
  cardLink: {
    marginTop: "5px",
    textDecoration: "none",
    color: "#000",
  },
  "@media (max-width: 768px)": {
    nav: {
      flexDirection: "column",
      alignItems: "center",
    },
    dropdown: {
      alignItems: "center",
    },
    productCard: {
      width: "90%",
    },
    cardsContainer: {
      width: "90%",
      flexDirection: "column",
      alignItems: "center",
    },
  },
};

export default Home;
