import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar/Sidebar";

const Admin = () => {
  const [products, setProducts] = useState([]);

  const [productData, setProductData] = useState({
    id: "", // Add this line
    name: "",
    description: "",
    price: "",
    image: "",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const navigate = useNavigate();

  // Fetch products on mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login"); // If no token, redirect to login
    }

    axios
      .get("http://localhost:5000/api/products", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setProducts(response.data.products))
      .catch(() => navigate("/login")); // If token is invalid, redirect to login
  }, [navigate, productData]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  // Handle file change
  const handleFileChange = (e) => {
    setProductData({ ...productData, image: e.target.files[0] });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("name", productData.name);
    formData.append("description", productData.description);
    formData.append("price", productData.price);
    formData.append("image", productData.image);

    try {
      if (isEditing) {
        // Update product
        const response = await axios.put(
          `http://localhost:5000/api/products/${productData._id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setProducts(
          products.map((product) =>
            product._id === productData.id ? response.data.product : product
          )
        );
      } else {
        // Add new product
        const response = await axios.post(
          "http://localhost:5000/api/products",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setProducts([...products, response.data.product]);
      }
      resetForm();
    } catch (error) {
      console.error("Failed to save product", error);
    }
  };

  // Handle product modification
  const handleModify = (product) => {
    setProductData({ ...product });
    setIsEditing(true);
  };

  // Handle product deletion
  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(id);
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(`http://localhost:5000/api/products/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(products.filter((product) => product._id !== id));
    } catch (error) {
      console.error("Failed to delete product", error);
    } finally {
      setDeleteConfirmation(null); // Reset confirmation
    }
  };

  const resetForm = () => {
    setProductData({ name: "", description: "", price: "", image: null });
    setIsEditing(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar onSectorChange={(newSector) => console.log(newSector)} />{" "}
      {/* Pass any necessary props to Sidebar */}
      <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
        <h1>Admin Panel - Product Management</h1>

        <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
          <input
            type="text"
            name="name"
            value={productData.name}
            onChange={handleInputChange}
            placeholder="Product Name"
            required
            style={{
              padding: "10px",
              width: "100%",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
          <input
            type="text"
            name="description"
            value={productData.description}
            onChange={handleInputChange}
            placeholder="Product Description"
            required
            style={{
              padding: "10px",
              width: "100%",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
          <input
            type="number"
            name="price"
            value={productData.price}
            onChange={handleInputChange}
            placeholder="Product Price"
            required
            style={{
              padding: "10px",
              width: "100%",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
          <input
            type="file"
            onChange={handleFileChange}
            required
            style={{ marginBottom: "10px" }}
          />

          <button
            type="submit"
            style={{
              padding: "10px",
              background: isEditing ? "#007BFF" : "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {isEditing ? "Modify Product" : "Add Product"}
          </button>
          {isEditing && (
            <button
              type="button"
              onClick={resetForm}
              style={{
                marginLeft: "10px",
                padding: "10px",
                background: "#dc3545",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Anullim
            </button>
          )}
        </form>

        <h2>Product List</h2>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                Image
              </th>
              <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                Name
              </th>
              <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                Description
              </th>
              <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                Price
              </th>
              <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                  <img
                    src={`http://localhost:5000/uploads/${product.image}`}
                    alt={product.name}
                    width="50"
                  />
                </td>
                <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {product.name}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {product.description}
                </td>

                <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                  ${product.price}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                  <button
                    onClick={() => handleModify(product)}
                    style={{
                      marginRight: "5px",
                      padding: "5px",
                      background: "#ffc107",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Modify
                  </button>
                  <button
                    onClick={() => handleDeleteConfirmation(product._id)}
                    style={{
                      padding: "5px",
                      background: "#dc3545",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Delete
                  </button>
                  {deleteConfirmation === product._id && (
                    <div style={{ marginTop: "10px" }}>
                      <p>Deshironi ta fshini?</p>
                      <button
                        onClick={() => handleDelete(product._id)}
                        style={{
                          marginRight: "5px",
                          padding: "5px",
                          background: "#dc3545",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => setDeleteConfirmation(null)}
                        style={{
                          padding: "5px",
                          background: "#007BFF",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        No
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;
