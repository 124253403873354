import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Home";
import Register from "./auth/Register";
import Login from "./auth/Login";
import Admin from "./Admin";
import "./App.css";

// Helper function to check if the user is authenticated
const isAuthenticated = () => !!localStorage.getItem("token");

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Universal Home Component for all users */}
          <Route path="/" element={<Home />} />

          {/* Register page */}
          <Route path="/register" element={<Register />} />

          {/* Login page */}
          <Route path="/login" element={<Login />} />

          {/* Admin page (protected route) */}
          <Route
            path="/admin"
            element={isAuthenticated() ? <Admin /> : <Navigate to="/login" />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
